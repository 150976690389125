import { z } from "zod";

import { PackageInvoiceOptionEnum } from "@lib/data/schemas/package-instance";
import {
  PackageContentTypeSchema,
  PackageFrequencySchema,
} from "@lib/data/schemas/packages";
import { SchedulerSchema } from "@lib/data/schemas/scheduler";

import { getSchemaDefinition } from "../common";

const tags = ["package-instances"];

const ChosenAppointmentSchema = z.object({
  schedulerId: z.string(),
  appointmentId: z.string(),
});

export type ChosenAppointmentType = z.infer<typeof ChosenAppointmentSchema>;

const AssignPackageToClientBodySchema = z.object({
  packageId: z.string(),
  paymentMethod: z.string().optional(),
  paymentOption: z.number().optional(),
  isAssignedByCoach: z.boolean(),
  invoiceOption: PackageInvoiceOptionEnum.optional(),
  promoCode: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  startDate: z.string().optional(),
  chosenAppointments: z.array(ChosenAppointmentSchema).optional(),
  quantity: z.number().optional(),
  customSubscriptionId: z.string().nullable().optional(),
  locked: z.boolean().optional(),
});

export type AssignPackageToClientBodyType = z.infer<
  typeof AssignPackageToClientBodySchema
>;

const AssignPackageToClientResponseSchema = z.object({
  packageInstanceId: z.string(),
  clientId: z.string(),
});

export type AssignPackageToClientResponseType = z.infer<
  typeof AssignPackageToClientResponseSchema
>;

export const deletePackageInstance = getSchemaDefinition(
  "/api/v1/users/{userId}/clients/{clientId}/package-instances/{packageInstanceId}",
  "delete",
  {
    path: z.object({
      userId: z.string(),
      clientId: z.string(),
      packageInstanceId: z.string(),
    }),
  },
  {
    description: "Delete package instance from a client",
    tags,
  },
  z.object({ status: z.string() })
);

const GetPackageSchedulerResponseSchema = z.array(
  SchedulerSchema.pick({
    id: true,
    title: true,
    description: true,
    writeCalendar: true,
    status: true,
    communication: true,
    availabilityId: true,
    icon: true,
    groupScheduler: true,
  }).extend({
    quantity: z.number().optional(),
    appointments: z
      .array(
        z.object({
          id: z.string(),
          start: z.any(),
          end: z.any(),
          packageInstanceCycle: z.number().optional(),
        })
      )
      .optional(),
    packageInstanceId: z.string().optional(),
    distributeSessions: z.boolean().optional(),
    totalSessions: z.number().nullable().optional(),
    duration: z.number().optional(),
    contentType: PackageContentTypeSchema.optional(),
    suggestedFrequency: PackageFrequencySchema.optional(),
  })
);

export const packageSchedulersGet = getSchemaDefinition(
  "/api/v1/users/{userId}/clients/{clientId}/package-instances-schedulers",
  "get",
  {
    path: z.object({
      userId: z.string(),
      clientId: z.string(),
    }),
    query: z.object({
      parentClientId: z.string().optional(),
    }),
  },
  {
    description: "Get package schedulers with associated appointments",
    tags,
  },
  GetPackageSchedulerResponseSchema
);

export type PackageSchedulerType = z.infer<
  typeof GetPackageSchedulerResponseSchema
>[0];

export const postAddAppointmentsToPackage = getSchemaDefinition(
  "/api/v1/users/{userId}/clients/{clientId}/package-instances/{packageInstanceId}/add-appointments",
  "post",
  {
    path: z.object({
      userId: z.string(),
      clientId: z.string(),
      packageInstanceId: z.string(),
    }),
    body: z.object({
      chosenAppointments: z.array(ChosenAppointmentSchema),
    }),
  },
  {
    description: "Add appointments to a package instance",
    tags,
  },
  z.object({ status: z.string() })
);

export const getPackageInstanceAppointments = getSchemaDefinition(
  "/api/v1/users/{userId}/clients/{clientId}/package-instances/{packageInstanceId}/package-instance-appointments",
  "get",
  {
    path: z.object({
      userId: z.string(),
      clientId: z.string(),
      packageInstanceId: z.string(),
    }),
  },
  {
    description: "Get appointments from package instance",
    tags,
  },
  z.object({ packageInstanceAppointments: z.any() })
);
